var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": _vm.supplier.name,
      "sub-title": "Supplier Management",
      "logo": _vm.supplier.logoUrl,
      "no-border": true
    }
  }), _c('TabView', {
    attrs: {
      "tabs": _vm.tabs,
      "loading": _vm.isLoading,
      "exact": false
    }
  }, [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }