<template>
  <div>
    <PageHeader :title="supplier.name" sub-title="Supplier Management" :logo="supplier.logoUrl" :no-border="true" />
    <TabView :tabs="tabs" :loading="isLoading" :exact="false">
      <router-view />
    </TabView>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import PageHeader from '@/components/PageHeader';
import TabView from '@/components/TabView';

export default {
  name: 'SupplierView',
  components: {
    PageHeader,
    TabView
  },
  data() {
    return {
      isLoading: false,
      searchTerm: ''
    };
  },
  computed: {
    ...mapGetters({
      supplier: 'supplier/supplier',
      loadingAction: 'supplier/loadingAction'
    }),
    currentTab() {
      return this.$route.params.tab || 'overview';
    },
    tabs() {
      return [
        {
          name: 'overview',
          route: 'supplier-overview',
          icon: 'fa-building'
        },
        {
          name: 'electricity',
          route: 'supplier-template-electricity',
          icon: 'fa-bolt'
        },
        {
          name: 'gas',
          route: 'supplier-template-gas',
          icon: 'fa-burn'
        },
        {
          name: 'solar',
          route: 'supplier-template-solar',
          icon: 'fa-solar-panel'
        },
        {
          name: 'waste',
          route: 'supplier-template-waste',
          icon: 'fa-biohazard'
        },
        {
          name: 'water',
          route: 'supplier-template-water',
          icon: 'fa-faucet-drip'
        },
        {
          name: 'heating',
          route: 'supplier-template-heating',
          icon: 'fa-heat'
        },
        {
          name: 'cooling',
          route: 'supplier-template-cooling',
          icon: 'fa-fan'
        },
        {
          name: 'edit',
          route: 'supplier-edit',
          icon: 'fa-cog'
        }
      ];
    }
  },
  async created() {
    this.isLoading = true;
    await this.getSupplier({ id: this.$route.params.id });
    this.isLoading = false;
  },
  methods: {
    ...mapActions({
      getSupplier: 'supplier/get'
    })
  }
};
</script>
